import * as React from "react"
import Card from "../components/Card"

const ContinueReading = props => {
  const blogPosts = require("../assets/data/blogsContent.json")
  // const strapiBlogContent = require("../assets/data/strapiContent.json")

  const visibleBlogPosts = blogPosts.filter(blogpost => {
    if (blogpost.title != props.title) {
      const tagSplit = blogpost.tag.split(",")

      return (
        tagSplit.filter(chunk => {
          if (props.tag.indexOf(chunk) != -1) {
            return blogpost
          }
        }).length > 0
      )
    }
  })

  function mycard(val) {
    return (
      <Card
        url={val.url}
        img={val.thumbnail?.formats.medium.url}
        category={val.blog_category.name}
        title={val.title}
        description={val.content[0]?.content}
        author_name={val.author.name}
        author_thumbnail={
          val.author.thumbnail?.formats?.thumbnail.url ||
          val.author.thumbnail?.url
        }
        published={val.published_at}
      />
    )
  }

  return visibleBlogPosts.length > 0 ? (
    <>
      <p className="text-3xl font-semibold blue-dark2 mb-6">Continue Reading</p>
      <div className="w-full flex flex-col lg:flex-row justify-center items-center flex-wrap">
        <div className="w-full flex lg:pl-16 flex-wrap justify-between">
          {visibleBlogPosts.map(mycard)}
        </div>
      </div>
    </>
  ) : null
}
export default ContinueReading
